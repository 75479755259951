import * as React from 'react';
import { ConcernWizardContainer } from '../..';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import FormInput from '../Fields/FormInput';
var Cognomen = function (props) { return (React.createElement("section", { className: "row row-centered" },
    React.createElement("div", null,
        React.createElement(FormInput, { label: "Your Name", name: "reporter_name", placeholder: "Anonymous", value: props.values.reporter_name, handleChange: props.handleChange, disabled: false, required: ConcernWizardContainer.isFieldRequired('reporter_name') }),
        React.createElement(InfoTooltip, null,
            React.createElement("p", null, "Remember, by not providing your name and contact details it will be more difficult for your employer to effectively investigate your concerns."))))); };
export default Cognomen;
