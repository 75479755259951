import * as React from 'react';
import FormInput from '../Fields/FormInput';
import FormTextArea from '../Fields/FormTextArea';
import styles from './styles.module.scss';
var Confirmation = function (props) { return (React.createElement("section", { className: 'row-centered' },
    React.createElement("section", { className: "row ".concat(styles.confirmation) },
        React.createElement("div", null,
            React.createElement("h2", null, "Review your submission."),
            React.createElement("p", null, "Please take a moment to check the fields below before you complete your submission."),
            React.createElement(FormInput, { label: 'Please confirm your name', name: 'reporter_name', placeholder: 'Anonymous', value: props.values.reporter_name, handleChange: props.handleChange, disabled: false }),
            React.createElement(FormInput, { label: 'Your Contact Telephone Number', name: 'reporter_number', placeholder: '+44 3300 414 996', value: props.values.reporter_number, handleChange: props.handleChange, disabled: false }),
            React.createElement(FormInput, { label: 'Please confirm the name of the business/organisation you are raising concerns about?', name: 'customer_name', placeholder: 'Company Name', value: props.values.customer_name, handleChange: props.handleChange, disabled: false })),
        React.createElement("div", null,
            React.createElement(FormTextArea, { label: 'Please provide details of the concern(s) you have, including dates and times if relevant.', name: 'message', placeholder: 'Please describe your concern or experience', handleChange: props.handleChange, value: props.values.message }))))); };
export default Confirmation;
