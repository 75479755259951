import * as React from 'react';
import { ConcernWizardContainer } from '../../../../index';
import CompanyDetails from '../../../CompanyDetails';
import FormInput from '../../../Fields/FormInput';
import styles from './styles.module.scss';
var WithUid = function (props) { return (React.createElement("section", { className: 'row row-centered' },
    React.createElement("div", null,
        React.createElement(CompanyDetails, { companyLogo: '', customerName: props.values.customer_name }),
        React.createElement(FormInput, { label: 'Please confirm this is the name of the business/organisation you are raising concerns about?', name: 'customer_name', value: props.values.customer_name, handleChange: props.handleChange, disabled: !props.withUidCompanyNameFieldEnabled, required: ConcernWizardContainer.isFieldRequired('customer_name') }),
        !props.withUidCompanyNameFieldEnabled ? (React.createElement("button", { className: "button button--grey ".concat(styles.editButton), onClick: props.editCompanyOnClick }, "Edit Company")) : null))); };
export default WithUid;
