import * as React from 'react';
var Completion = function (_a) {
    var reference = _a.reference;
    return (React.createElement("section", { className: "row", style: { textAlign: 'center' } },
        React.createElement("p", null, "Thank you for providing this information."),
        React.createElement("p", null, "This will now be submitted to your employer for further investigation."),
        React.createElement("p", null,
            "Your unique reference number is ",
            React.createElement("strong", null,
                "#",
                reference),
            ". Please use this in any correspondence.")));
};
export default Completion;
