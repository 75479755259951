import { __awaiter, __generator } from "tslib";
import request from '..';
export function index(params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request('/customers', 'GET', null, params)];
        });
    });
}
export function validate(uid) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request("/customers/".concat(uid, "/validate"), 'GET', null, null)];
        });
    });
}
export function create(data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request('/customers', 'POST', data, null)];
        });
    });
}
export function update(id, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request("/customers/".concat(id), 'PUT', data, null)];
        });
    });
}
export function get(id) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request("/customers/".concat(id), 'GET', null, null)];
        });
    });
}
export function search(term) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request('/customers/search', 'POST', { term: term }, null)];
        });
    });
}
