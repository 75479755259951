import { __extends } from "tslib";
import * as React from 'react';
import Button from './components/button';
import InfoBox from './components/InfoBox';
import styles from './styles.module.scss';
var InfoTooltip = /** @class */ (function (_super) {
    __extends(InfoTooltip, _super);
    function InfoTooltip() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            enabled: false,
        };
        _this.toggleEnabled = function (setEnabledTo) {
            if (setEnabledTo !== undefined) {
                _this.setState({
                    enabled: setEnabledTo,
                });
            }
            else {
                _this.setState({
                    enabled: !_this.state.enabled,
                });
            }
        };
        return _this;
    }
    InfoTooltip.prototype.handleMouseEvent = function (e, setEnabledTo) {
        var target = e.target;
        if (!target.classList.contains('tooltip')) {
            this.toggleEnabled(false);
        }
    };
    InfoTooltip.prototype.componentDidMount = function () {
        var _this = this;
        document.addEventListener('mousedown', function (e) { return _this.handleMouseEvent(e, false); }, false);
    };
    InfoTooltip.prototype.componentWillUnmount = function () {
        var _this = this;
        document.removeEventListener('mousedown', function (e) { return _this.handleMouseEvent(e, false); }, false);
    };
    InfoTooltip.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles['info-tooltip'] },
            React.createElement(InfoBox, { enabled: this.state.enabled }, this.props.children),
            React.createElement(Button, { toggleEnabled: function (e) { return _this.toggleEnabled(); } })));
    };
    return InfoTooltip;
}(React.Component));
export default InfoTooltip;
