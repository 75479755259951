import { __awaiter, __extends, __generator } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { throttle } from 'throttle-debounce';
import Button, { Colours } from '../../../../components/Button';
import { history } from '../../../../router';
import { validate } from '../../../../services/customer';
import styles from './styles.module.scss';
var CustomerCodeForm = /** @class */ (function (_super) {
    __extends(CustomerCodeForm, _super);
    function CustomerCodeForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            customerCode: '',
            message: '',
            loading: false,
        };
        _this.checkCompanyIdThrottled = throttle(100, function () { return _this.checkCompanyId(); }, false);
        _this.checkCompanyId = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                console.log('Checking Company is Valid');
                validate(this.state.customerCode)
                    .then(function (res) {
                    var _a = res.customer, uid = _a.uid, active = _a.active;
                    if (active !== false) {
                        history.push("/concern/company?id=".concat(uid));
                    }
                    else {
                        console.log("We're sorry but the whistlebox service provided to this company has now expired.");
                        _this.setState({
                            message: "We're sorry but the whistlebox service provided to this company has now expired.",
                        });
                    }
                })
                    .catch(function (error) {
                    _this.setState({
                        message: 'You need to use your companies unique whistlebox code to use the online platform',
                    });
                });
                return [2 /*return*/];
            });
        }); };
        _this.handleCustomerCodeChange = function (e) {
            _this.setState({
                customerCode: e.target.value,
            });
        };
        _this.handleCustomerCodeSubmission = function (e) {
            e.preventDefault();
            _this.setState({
                loading: true,
            }, function () { return _this.checkCompanyIdThrottled(); });
        };
        return _this;
    }
    CustomerCodeForm.prototype.render = function () {
        var _a, _b, _c;
        var _this = this;
        return (React.createElement("section", { className: classNames((_a = {}, _a[styles.formArea] = true, _a)) },
            React.createElement("div", null,
                React.createElement("h2", null,
                    "Do you have your",
                    ' ',
                    React.createElement("span", { style: { color: '#cc0000' } }, "Unique Company Code?")),
                React.createElement("p", { className: styles.formAreaDescription }, "Enter your five digit Unique Company Code below to continue. This will have been provided by your employer as a means of access to this service."),
                React.createElement("form", { className: classNames((_b = {},
                        _b[styles.authForm] = true,
                        _b[styles.animateIn] = this.props.visible,
                        _b)), onSubmit: function (e) { return _this.handleCustomerCodeSubmission(e); } },
                    React.createElement("div", { className: styles.formGroup },
                        React.createElement("input", { onChange: function (e) { return _this.handleCustomerCodeChange(e); }, type: 'text', maxLength: 5, placeholder: 'Enter your Unique Company Code' }),
                        React.createElement("span", { className: classNames((_c = {},
                                _c[styles.message] = this.state.message.length,
                                _c)) }, this.state.message)),
                    React.createElement("div", { className: styles.formFooter },
                        React.createElement(Button, { text: 'Login', colour: Colours.Red, disabled: this.state.customerCode.length < 5, type: 'submit' }))),
                React.createElement("p", { className: styles.formInformation }, "Your employer takes concerns of this matter seriously and is committed to investigating the details you provide. Once you have responded to all the questions, including details of your concerns, they will be submitted to your employer to support their investigation."))));
    };
    return CustomerCodeForm;
}(React.Component));
export default CustomerCodeForm;
