import * as React from 'react';
import { Route, Routes } from 'react-router';
import SiteLayout from '../layouts/SiteLayout';
import About from './about';
import Concern from './concern';
import Home from './home';
var Index = function () { return (React.createElement(SiteLayout, null,
    React.createElement(Routes, null,
        React.createElement(Route, { path: '/', element: React.createElement(Home, null) }),
        React.createElement(Route, { path: '/concern/*', element: React.createElement(Concern, null) }),
        React.createElement(Route, { path: '/about', element: React.createElement(About, null) })))); };
export default Index;
