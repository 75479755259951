import window from 'global';
import * as React from 'react';
import Branding from '../Branding';
import styles from './styles.module.scss';
var LandingBlock = function (_a) {
    var title = _a.title, message = _a.message;
    // tslint:disable-next-line: one-variable-per-declaration
    var params, errorMessage;
    React.useEffect(function () {
        console.log(styles);
    });
    if (window && window.location && window.location.search !== '') {
        params = new URLSearchParams(window.location.search.slice(1));
        errorMessage = params.has('message') && params.get('message');
    }
    else {
        errorMessage = 'There seems to have been an error.';
    }
    return (React.createElement("div", { className: styles['landing-block'] },
        React.createElement(Branding, { WordMarkFill: '#000' }),
        React.createElement("h1", null, title),
        React.createElement("h2", null, errorMessage),
        React.createElement("p", null, message),
        React.createElement("a", { href: '/' }, "Click here for the homepage")));
};
LandingBlock.defaultProps = {
    title: 'Error',
    message: "An error occured loading the page. We're sorry for the inconvenience.",
};
export default LandingBlock;
