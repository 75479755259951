import * as React from 'react';
var logo = require('./img/whistlebox-logo-white.svg');
// import WordMark from './components/wordmark';
import styles from './styles.module.scss';
var Branding = function (_a) {
    var href = _a.href, WordMarkFill = _a.WordMarkFill;
    return (React.createElement("div", { className: styles.branding },
        React.createElement("a", { href: href, title: '' },
            React.createElement("img", { src: logo, alt: 'rradar Logo' }))));
};
Branding.defaultProps = {
    href: '/',
};
export default Branding;
