import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons/faPenAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/free-solid-svg-icons/faSortAlphaUp';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret';
config.autoAddCss = false;
library.add(faCheck, faEnvelope, faExternalLinkAlt, faFilter, faGlobe, faInfo, faKey, faLock, faPaperPlane, faPenAlt, faPhone, faPlus, faQuestion, faSort, faSortAlphaDown, faSortAlphaUp, faTimes, faTrash, faUserSecret);
