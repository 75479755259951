import { __assign, __rest } from "tslib";
import { Field } from 'formik';
import * as React from 'react';
import FormLabel from '../FormLabel';
import styles from '../styles.module.scss';
var customInputComponent = function (_a) {
    var field = _a.field, form = _a.form, props = __rest(_a, ["field", "form"]);
    return (React.createElement("div", { className: styles.container },
        form.touched[field.name] && form.errors[field.name] && (React.createElement("div", { className: styles.error }, form.errors[field.name])),
        React.createElement("input", __assign({ type: 'text' }, field, props, { className: styles.input })),
        props.required ? React.createElement("div", { className: 'required-asterix' }) : null));
};
var FormInput = function (_a) {
    var label = _a.label, name = _a.name, handleChange = _a.handleChange, value = _a.value, placeholder = _a.placeholder, autoCorrect = _a.autoCorrect, spellCheck = _a.spellCheck, disabled = _a.disabled, required = _a.required;
    return (React.createElement(React.Fragment, null,
        React.createElement(FormLabel, null, label),
        React.createElement(Field, { placeholder: placeholder || '', name: name, onChange: function (e) { return handleChange(e); }, component: customInputComponent, disabled: disabled, required: required, value: value, autoCorrect: autoCorrect, spellCheck: spellCheck })));
};
export default FormInput;
