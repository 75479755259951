import { Field } from 'formik';
import * as React from 'react';
import { TrixEditor } from 'react-trix';
import FormLabel from '../FormLabel';
var trixEditor = React.createRef();
var handleTrixContent = function (key, html, setFieldValue) {
    setFieldValue(key, html);
};
var handleEditorReady = function (editor, value) {
    editor.insertHTML(value);
};
var FormTextArea = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(FormLabel, null, props.label),
        React.createElement(Field, { name: props.name, render: function (formProps) { return (React.createElement(TrixEditor, { mergeTags: null, onChange: function (html) { return handleTrixContent(props.name, html, formProps.form.setFieldValue); }, placeholder: props.placeholder || '', ref: trixEditor, onEditorReady: function (editor) { return handleEditorReady(editor, props.value); } })); }, value: props.value, required: props.required })));
};
export default FormTextArea;
