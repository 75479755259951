import { __awaiter, __generator } from "tslib";
import request from '..';
export function create(concern) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request('/concern', 'POST', concern, null)];
        });
    });
}
export function getConcern(id) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request("/concerns/".concat(id), 'GET', null, null)];
        });
    });
}
export function assign(id, assignment) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request("/concerns/".concat(id, "/assign"), 'PUT', assignment, null)];
        });
    });
}
export function close(id, send) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request("/concerns/".concat(id, "/close"), 'PUT', {
                    send: send,
                }, null)];
        });
    });
}
