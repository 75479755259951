import * as React from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var Hero = function () { return (React.createElement("div", { className: styles.hero },
    React.createElement("p", { className: styles.subtitle }, "Remove the barrier to raising concerns in your workplace and provide a safe working environment for your people."),
    React.createElement("p", null, "All organisations face the risk of things going wrong or of unknowingly harbouring illegal or unethical conduct. Raising such serious matters can feel unnerving and worrying for your staff members and managers."),
    React.createElement("p", null,
        React.createElement("strong", null, "whistlebox"),
        " is a 24/7 reporting service from rradar, a specialist commercial and litigation law firm expert in helping organisations through times of crisis and empowering them to take the best course of action."),
    React.createElement("p", null,
        React.createElement("strong", null, "whistlebox"),
        " can provide your organisation a clear whistleblowing policy and procedure to follow, and gives your employees a safe and independent channel to voice their concerns."),
    React.createElement("div", { className: styles.formContact },
        React.createElement("h4", null, "To find out more about whistlebox, visit:"),
        React.createElement("a", { href: 'https://www.rradar.com/whistlebox', title: 'Whistlebox service information', target: '_blank' },
            React.createElement(FontAwesomeIcon, { icon: ['fas', 'globe'] }),
            " www.rradar.com/whistlebox"),
        ' ',
        React.createElement("br", null),
        React.createElement("p", null, "To report a concern, use the details in your organisation's welcome pack.")))); };
export default Hero;
