import * as Sentry from '@sentry/browser';
import 'flatpickr-css';
import 'flatpickr-theme';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'trix-js';
import './icons';
import './polyfills';
import Router from './router';
import './styles.scss';
import getState from './utils/getState';
if (getState().sentry.enabled) {
    Sentry.init({ dsn: getState().sentry.dsn });
}
window.addEventListener('DOMContentLoaded', function () {
    var target = document.getElementById('root');
    if (target) {
        ReactDOM.render(React.createElement(Router, null), document.getElementById('root'));
    }
    else {
        throw new Error('No target element for ReactDOM found.');
    }
});
