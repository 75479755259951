import * as React from 'react';
import { ConcernWizardContainer } from '../..';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import FormInput from '../Fields/FormInput';
var Contact = function (props) { return (React.createElement("section", { className: "row  row-centered" },
    React.createElement("div", null,
        React.createElement(FormInput, { label: "Your Contact Telephone Number", name: "reporter_number", placeholder: "+44 3300 414 996", value: props.values.reporter_number, handleChange: props.handleChange, disabled: false, required: ConcernWizardContainer.isFieldRequired('reporter_number') }),
        React.createElement(InfoTooltip, null,
            React.createElement("h3", null, "We Care About Your Privacy"),
            React.createElement("p", null, "This is a confidential service that has been set up by your employer to provide you with an external method to raise any concerns about any suspected wrongdoing or dangers at work directly to you or any of your colleagues"))))); };
export default Contact;
