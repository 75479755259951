import Cookies from 'js-cookie';
var AuthTokenHandler = /** @class */ (function () {
    function AuthTokenHandler() {
    }
    AuthTokenHandler.prototype.get = function () {
        return Cookies.get('sessionId');
    };
    AuthTokenHandler.prototype.set = function (sessionId) {
        Cookies.set('sessionId', sessionId);
    };
    return AuthTokenHandler;
}());
export default AuthTokenHandler;
