import * as React from 'react';
import Button, { Colours } from '../../components/Button';
import styles from './styles.module.scss';
var About = function () { return (React.createElement("div", { className: "row ".concat(styles.content) },
    React.createElement("div", null,
        React.createElement("p", null,
            React.createElement("strong", null, "This is a confidential service that has been set up by your employer to provide you with an external method to raise any concerns about any suspected wrongdoing or dangers at work directly to you or any of your colleagues.")),
        React.createElement("p", null, "Your employer takes matters of this nature seriously and is committed to investigating all concerns received.")),
    React.createElement(Button, { onClick: function () { return (window.location.pathname = '/'); }, text: 'Return', colour: Colours.Red }))); };
export default About;
