import { __assign, __awaiter, __generator } from "tslib";
import AuthTokenHandler from '../utils/AuthTokenHandler';
import getState from '../utils/getState';
var convertParamsToQueryString = function (input) {
    var params = new URLSearchParams();
    if (input.filters) {
        input.filters.forEach(function (_a) {
            var key = _a.key, value = _a.value;
            params.append(key, value);
        });
        delete input.filters;
    }
    if (input.sorts) {
        var sorts = input.sorts.map(function (_a) {
            var key = _a.key, order = _a.order;
            return "".concat(key, ":").concat(order);
        }).join(',');
        params.append('sort', sorts);
        delete input.sorts;
    }
    Object.entries(input).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        params.append(key, value);
    });
    return params.toString();
};
export default function request(endpoint, method, requestData, params) {
    if (method === void 0) { method = 'GET'; }
    if (requestData === void 0) { requestData = null; }
    return __awaiter(this, void 0, void 0, function () {
        var url, response, json;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(getState().api.endpoint).concat(endpoint).concat(params != null ? "?".concat(convertParamsToQueryString(params)) : '');
                    return [4 /*yield*/, fetch(url, __assign({ headers: {
                                'Authorization': new AuthTokenHandler().get(),
                                'Content-Type': 'application/json'
                            }, method: method }, (requestData != null ? { body: JSON.stringify(requestData) } : {})))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    json = _a.sent();
                    return [2 /*return*/, response.ok ? json : Promise.reject(json)];
            }
        });
    });
}
