import { __assign } from "tslib";
import * as React from 'react';
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        var location = useLocation();
        var navigate = useNavigate();
        var params = useParams();
        return React.createElement(Component, __assign({}, props, { router: { location: location, navigate: navigate, params: params } }));
    }
    return ComponentWithRouterProp;
}
export default withRouter;
