import { __assign, __extends } from "tslib";
import * as React from 'react';
var withUnloading = function (WrappedComponent, unloadText) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                enabled: false
            };
            _this.setUnloaderEnabledStatus = function (enabled) {
                _this.setState({ enabled: enabled });
            };
            _this.addUnloadEvent = function () {
                window.addEventListener('beforeunload', _this.beforeUnload);
            };
            _this.beforeUnload = function (event) {
                event.preventDefault();
                event.returnValue = unloadText;
            };
            return _this;
        }
        class_1.prototype.componentDidUpdate = function (prevProps, prevState) {
            if (!prevState.enabled && this.state.enabled) {
                this.addUnloadEvent();
            }
            if (prevState.enabled && !this.state.enabled) {
                this.removeUnloadEvent();
            }
        };
        class_1.prototype.render = function () {
            return (React.createElement(WrappedComponent, __assign({}, this.props, { unloaderIsEnabled: true, setUnloaderEnabledStatus: this.setUnloaderEnabledStatus })));
        };
        class_1.prototype.removeUnloadEvent = function () {
            window.removeEventListener('beforeunload', this.beforeUnload);
        };
        return class_1;
    }(React.Component));
};
export default withUnloading;
