import { __assign } from "tslib";
import * as React from 'react';
import * as Loadable from 'react-loadable';
import { Route, Routes, Navigate, unstable_HistoryRouter as HistoryRouter, } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loading from './components/Loading';
import Index from './views';
import Error from './views/error';
export var history = createBrowserHistory();
var Admin = Loadable({
    loader: function () { return import(/* webpackChunkName: "admin" */ './views/admin'); },
    loading: function (props) { return React.createElement(Loading, __assign({}, props)); },
});
//<Navigate replace to='/error' />
var Router = function () { return (React.createElement(HistoryRouter, { history: history },
    React.createElement(Routes, null,
        React.createElement(Route, { path: '/error', element: React.createElement(Error, null) }),
        React.createElement(Route, { path: '/admin/*', element: React.createElement(Admin, null) }),
        React.createElement(Route, { path: '/*', element: React.createElement(Index, null) }),
        React.createElement(Route, { path: '*', element: React.createElement(Navigate, { replace: true, to: '/error' }) })))); };
export default Router;
