import classNames from 'classnames';
import * as React from 'react';
import SiteHeader from '../../components/SiteHeader';
var SiteLayout = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(SiteHeader, null),
        React.createElement("div", { className: classNames({
                'site-wrapper': true,
                'site-wrapper-home': window.location.pathname === '/',
            }) }, props.children)));
};
export default SiteLayout;
