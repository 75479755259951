import * as React from 'react';
import styles from './styles.module.scss';
var Header = function (props) { return (React.createElement("div", { style: {
        background: 'linear-gradient(-297deg,#242424,#333 50%,#2d2d2d 0,#2d2d2d)',
        color: 'white',
        position: 'relative',
        zIndex: 99,
    } },
    React.createElement("header", { className: styles.header }, props.children))); };
export default Header;
