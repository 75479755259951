import { __extends } from "tslib";
import * as React from 'react';
import CustomerCodeForm from './components/CustomerCodeForm';
import Hero from './components/Hero';
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            displayCompanyCodeForm: false,
        };
        _this.toggleCustomerCodeForm = function () {
            _this.setState(function (prevState) { return ({
                displayCompanyCodeForm: !prevState.displayCompanyCodeForm,
            }); });
        };
        return _this;
    }
    Home.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Hero, null),
            React.createElement(CustomerCodeForm, { visible: this.state.displayCompanyCodeForm })));
    };
    return Home;
}(React.Component));
export default Home;
