import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
export var Colours;
(function (Colours) {
    Colours[Colours["Grey"] = 0] = "Grey";
    Colours[Colours["Lightgrey"] = 1] = "Lightgrey";
    Colours[Colours["Red"] = 2] = "Red";
})(Colours || (Colours = {}));
var Button = function (_a) {
    var _b;
    var onClick = _a.onClick, text = _a.text, disabled = _a.disabled, colour = _a.colour, type = _a.type;
    return (React.createElement("button", { className: classNames((_b = {},
            _b[styles.button] = true,
            _b[styles.red] = colour === Colours.Red,
            _b[styles.grey] = colour === Colours.Grey,
            _b[styles.lightgrey] = colour === Colours.Lightgrey,
            _b)), disabled: disabled, onClick: onClick, type: type ||
            'button' }, text));
};
export default Button;
