import * as React from 'react';
import { ConcernWizardContainer } from '../..';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import FormTextArea from '../Fields/FormTextArea';
var Concern = function (props) { return (React.createElement("div", { className: "row row-centered" },
    React.createElement("div", null,
        React.createElement(FormTextArea, { label: "Please provide details of the concern(s) you have, including dates and times if relevant.", name: "message", placeholder: "Please describe your concern or experience", handleChange: props.handleChange, value: props.values.message, required: ConcernWizardContainer.isFieldRequired('message') }),
        React.createElement(InfoTooltip, null,
            React.createElement("p", null, "When giving details of the concern(s) you have, please provide as much information as you can including dates and times."))))); };
export default Concern;
