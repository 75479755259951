import { __assign, __awaiter, __extends, __generator, __spreadArray } from "tslib";
import { withFormik, } from 'formik';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as Yup from 'yup';
import Button, { Colours } from '../../../../components/Button';
import { create as report } from '../../../../services/concern';
import Cognomen from './components/Cognomen';
import Company from './components/Company';
import Completion from './components/Completion';
import Concern from './components/Concern';
import Confirmation from './components/Confirmation';
import Contact from './components/Contact';
import Validator from './validator';
var isEqual = require('lodash.isequal');
import { compose } from 'recompose';
import { history } from '../../../../router';
import { validate } from '../../../../services/customer';
import withUnloading from '../../../../utils/withUnloading';
import withRouter from '../../../../hooks/withRouter';
var ConcernWizardContainer = /** @class */ (function (_super) {
    __extends(ConcernWizardContainer, _super);
    function ConcernWizardContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formikRef = React.createRef();
        _this.unlisten = history.listen(function (_a) {
            var location = _a.location, action = _a.action;
            if (location.pathname === '/concern/confirmation' && action === 'POP') {
                history.push('/');
            }
        });
        _this.state = {
            prevPath: null,
            nextPath: null,
            reference: null,
            withUidCompanyNameFieldEnabled: false,
            currentPageInputsAreInvalid: false,
        };
        // setupEventListener = () => {
        //   window.addEventListener(
        //     'keydown',
        //     (event: KeyboardEvent) => {
        //       event.key && event.key === 'Enter' ? this.navigateForwards() : null;
        //     },
        //     false
        //   );
        // };
        _this.findTheInputField = function () {
            if (document.querySelectorAll('input')[0] !== undefined) {
                document.querySelectorAll('input')[0].focus();
            }
        };
        _this.navigateForwards = function () {
            _this.navigateToPath(_this.state.nextPath);
        };
        _this.navigateBackwards = function () {
            _this.navigateToPath(_this.state.prevPath);
        };
        _this.submitForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                report(this.props.values)
                    .then(function (res) {
                    var reference = res.concern.ref;
                    _this.navigateToPath('/concern/complete');
                    _this.setState({ reference: reference });
                })
                    .catch(function (error) {
                    alert("".concat(error.message, ", your concern was not submitted.\n\nPlease try again."));
                });
                return [2 /*return*/];
            });
        }); };
        _this.setCurrentInputValidityState = function () {
            var errors = _this.props.errors;
            var node = ReactDOM.findDOMNode(_this);
            var inputs = node.querySelectorAll('input');
            var inputNames = Array.from(inputs).map(function (input) { return input.name; });
            inputNames = __spreadArray(__spreadArray([], inputNames, true), (document.querySelector('trix-editor') != null ? ['message'] : []), true);
            var currentPageInputsAreInvalid = Object.keys(errors).some(function (key) {
                return inputNames.includes(key);
            });
            _this.setState({ currentPageInputsAreInvalid: currentPageInputsAreInvalid });
        };
        _this.fetchCompanyName = function () { return __awaiter(_this, void 0, void 0, function () {
            var uid, customer_name;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uid = this.props.values.uid;
                        if (uid == null) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, validate(uid)];
                    case 1:
                        customer_name = (_a.sent()).customer.name;
                        this.props.setValues(__assign(__assign({}, this.props.values), { customer_name: customer_name }));
                        return [2 /*return*/];
                }
            });
        }); };
        _this.editCompanyOnClick = function () {
            _this.enableCompanyNameField();
        };
        _this.enableCompanyNameField = function () {
            _this.setState({ withUidCompanyNameFieldEnabled: true });
            _this.props.setFieldValue('uid', null);
        };
        return _this;
    }
    ConcernWizardContainer.getCompanyId = function () {
        var params = new URLSearchParams(window.location.search.slice(1));
        var companyId = params.get('id');
        return companyId;
    };
    ConcernWizardContainer.prototype.componentDidMount = function () {
        this.updatePaths();
        this.fetchCompanyName();
        // https://github.com/jaredpalmer/formik/issues/288#issuecomment-381914693
        this.props.validateForm();
        this.setCurrentInputValidityState();
        // this.setupEventListener();
    };
    ConcernWizardContainer.prototype.componentDidUpdate = function (prevProps) {
        this.findTheInputField();
        var locationHasChanged = prevProps.router.location.pathname !==
            this.props.router.location.pathname;
        var valuesHasChanged = !isEqual(prevProps.values, this.props.values);
        var errorsHasChanged = !isEqual(prevProps.errors, this.props.errors);
        var dirtyHasChanged = prevProps.dirty !== this.props.dirty;
        if (locationHasChanged) {
            this.updatePaths();
        }
        if (locationHasChanged || valuesHasChanged || errorsHasChanged) {
            this.setCurrentInputValidityState();
        }
        if (dirtyHasChanged) {
            this.props.setUnloaderEnabledStatus(this.props.dirty);
        }
    };
    ConcernWizardContainer.prototype.componentWillUnmount = function () {
        this.unlisten();
    };
    ConcernWizardContainer.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: '/', element: React.createElement(Navigate, { to: '/company' }) }),
                React.createElement(Route, { path: '/company', element: React.createElement(Company
                    // {...props}
                    , { 
                        // {...props}
                        uid: this.props.values.uid, handleChange: this.props.handleChange, values: this.props.values, editCompanyOnClick: this.editCompanyOnClick, withUidCompanyNameFieldEnabled: this.state.withUidCompanyNameFieldEnabled }) }),
                React.createElement(Route, { path: '/contact', element: React.createElement(Contact, { handleChange: this.props.handleChange, values: this.props.values }) }),
                React.createElement(Route, { path: '/message', element: React.createElement(Concern, { handleChange: this.props.handleChange, values: this.props.values }) }),
                React.createElement(Route, { path: '/cognomen', element: React.createElement(Cognomen, { handleChange: this.props.handleChange, values: this.props.values }) }),
                React.createElement(Route, { path: '/confirmation', element: React.createElement(Confirmation, { handleChange: this.props.handleChange, values: this.props.values }) }),
                React.createElement(Route, { path: '/complete', element: React.createElement(Completion, { reference: this.state.reference }) })),
            React.createElement("section", { className: 'row buttonRow' }, this.isCompletionPage() ? (React.createElement("div", { style: { textAlign: 'center', margin: '2rem auto' } },
                React.createElement(Button, { colour: Colours.Red, onClick: function () { return history.push('/'); }, text: 'Back to Homepage' }))) : (React.createElement(React.Fragment, null,
                this.state.prevPath != null ? (React.createElement(Button, { colour: Colours.Red, onClick: this.navigateBackwards, text: 'Previous' })) : null,
                this.state.nextPath != null && !this.currentPathIsFinalPath() ? (React.createElement(Button, { colour: Colours.Red, onClick: this.navigateForwards, disabled: this.state.currentPageInputsAreInvalid, text: 'Next' })) : null,
                this.currentPathIsFinalPath() ? (React.createElement(Button, { colour: Colours.Red, onClick: this.submitForm, text: 'Submit', disabled: !this.props.isValid })) : null)))));
    };
    ConcernWizardContainer.prototype.currentPathIsFinalPath = function () {
        return this.props.router.location.pathname === '/concern/confirmation';
    };
    ConcernWizardContainer.prototype.isCompletionPage = function () {
        return this.props.router.location.pathname === '/concern/complete';
    };
    ConcernWizardContainer.prototype.navigateToPath = function (path) {
        history.push(path);
    };
    ConcernWizardContainer.prototype.getPaths = function () {
        var routes = [
            '/company',
            '/contact',
            '/message',
            '/cognomen',
            '/confirmation',
            '/complete',
        ];
        var currentPath = this.props.router.location.pathname;
        var currentChildIndex = routes.indexOf('/' + currentPath.split('/')[2]);
        var prevChild = currentChildIndex - 1 > -1
            ? '/concern' + routes[currentChildIndex - 1]
            : null;
        var nextChild = currentChildIndex + 1 < 6
            ? '/concern' + routes[currentChildIndex + 1]
            : null;
        return {
            prevPath: prevChild,
            nextPath: nextChild,
        };
    };
    ConcernWizardContainer.prototype.updatePaths = function () {
        this.setState(this.getPaths());
    };
    ConcernWizardContainer.isFieldRequired = function (fieldName) {
        var validationSchema = Yup.reach(Validator, '');
        return validationSchema.fields[fieldName]
            ? validationSchema.fields[fieldName].tests.some(function (_a) {
                var TEST_NAME = _a.TEST_NAME;
                return TEST_NAME === 'required';
            })
            : false;
    };
    return ConcernWizardContainer;
}(React.Component));
export { ConcernWizardContainer };
export default compose(withRouter, withUnloading, withFormik({
    mapPropsToValues: function () { return ({
        uid: ConcernWizardContainer.getCompanyId(),
        customer_name: '',
        reporter_name: '',
        reporter_number: '',
        reporter_email: '',
        message: '',
    }); },
    validationSchema: Validator,
    handleSubmit: function () {
        throw new Error('Incorrect handleSubmit method used, please use the class method.');
    },
}))(ConcernWizardContainer);
